// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-details-page-details-page-js": () => import("./../../../src/components/DetailsPage/DetailsPage.js" /* webpackChunkName: "component---src-components-details-page-details-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-3467-js": () => import("./../../../src/pages/landing-3467.js" /* webpackChunkName: "component---src-pages-landing-3467-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

